.home {
  width: 100%;
  align-items: center;
  font-family: "Gill Sans Extrabold", sans-serif;
  color: #000000;
}

.about {
  width: 100%;
  height: calc(100vh - 100px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-color: #d7d9e3;
  color: #020202;
}

.about h2 {
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  font-size: 50px;
  color: #050401;
  height: 50px;
}

.about .prompt {
  width: 40%;
  font-size: 20px;
}

.prompt svg {
  font-size: 60px;
  margin: 5px;
  color: #040202;
}

.skills {
  font-size: 30px;
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.skills h1 {
  color: #000000;
}

.skills .skills-grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: center; /* Center the items */
  width: 60%; /* Adjusted width to fit layout */
  margin: 20px 0; /* Spacing around the grid */
}

.skills .skill-item {
  width: 45%; /* Adjusted width for two columns */
  background: rgba(255, 255, 255, 0.1); /* Optional background for visibility */
  padding: 15px; /* Increased padding */
  margin: 10px; /* Spacing between items */
  border-radius: 8px; /* Optional rounded corners */
  color: white;
  display: flex;
  flex-direction: column; /* Stack icon and text vertically */
  align-items: center; /* Center items horizontally */
  justify-content: center; /* Center items vertically */
  transition: transform 0.2s; /* Smooth hover effect */
}

.skills .skill-item:hover {
  transform: scale(1.05); /* Slightly enlarge on hover */
}

.skills .skill-item h2 {
  margin-top: 10px; /* Space between icon and text */
  font-size: 24px; /* Increased font size */
  text-align: center; /* Center the text */
}

.skills .list {
  list-style: none;
  width: 60%;
}

.list span {
  font-size: 30px;
}

@media only screen and (max-width: 600px) {
  .about h2 {
    font-size: 20px;
  }

  .about .prompt {
    margin-top: 10px;
    font-size: 10px;
    width: 80%;
  }

  .skills {
    text-align: center;
  }

  .skills h1 {
    font-size: 30px;
  }

  .skills .skills-grid {
    width: 90%; /* Full width on smaller screens */
  }

  .skills .skill-item {
    width: 100%; /* Full width on small screens */
  }

  .skills .skill-item h2 {
    font-size: 20px; /* Adjust text size for small screens */
  }
}