.footer {
  width: 100%;
  height: 200px;
  background: #f6f6f6;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-top: 10px;
}

.socialMedia svg {
  color: rgb(7, 0, 0);
  margin: 20px;
  font-size: 70px;
  cursor: pointer;
}

.footer p {
  color: rgb(0, 0, 0);
}

@media only screen and (max-width: 600px) {
  .footer svg {
    font-size: 50px;
  }
}